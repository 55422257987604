var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Teams"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',{staticClass:"is-narrow"},[_c('router-link',{attrs:{"to":{
        name: 'create-team'
      }}},[_vm._v("Create Team")])],1)],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.teams.data),function(team){return _c('div',{key:team.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{attrs:{"to":{
                  name: 'team-manager',
                  params: {
                    team: team.uuid
                  }
                }}},[_vm._v(" "+_vm._s(team.name)+" "),_c('br'),_c('span',{staticClass:"has-text-grey"},[_vm._v("Lead by "+_vm._s(team.leader.full_name))])])],1),_c('column',{staticClass:"is-narrow"},[_c('index-info',{attrs:{"icon":"street-view","label":"Users","info":team.users_count}})],1)],1)],1)}),0),(_vm.teams.data.length)?_c('pager',{attrs:{"pageable":_vm.teams,"context":"Team","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.teams.data.length,"with-link":"","heading":"There are no teams available for display.","to":{
          name: 'create-team'
        },"to-text":"New Team"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }