<template>
  <loader v-bind="{ loading }" text="Loading Teams">
    <columns>
      <column class="is-narrow">
        <router-link :to="{
          name: 'create-team'
        }">Create Team</router-link>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless"
            v-for="team in teams.data" :key="team.id">
              <columns>
                <column>
                  <router-link :to="{
                    name: 'team-manager',
                    params: {
                      team: team.uuid
                    }
                  }">
                    {{ team.name }}
                    <br>
                    <span class="has-text-grey">Lead by {{ team.leader.full_name }}</span>
                  </router-link>
                </column>
                <column class="is-narrow">
                  <index-info 
                    icon="street-view" 
                    label="Users" 
                    :info="team.users_count" 
                  />
                </column>
              </columns>
          </div>
        </div>

        <pager v-if="teams.data.length" :pageable="teams" context="Team" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="teams.data.length"
          with-link
          heading="There are no teams available for display."
          :to="{
            name: 'create-team'
          }"
          to-text="New Team"
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
export default {

  data: () => ({
    loading: true,
    teams: {
      data: []
    }
  }),

  created() {
    this.loadTeams()
  },

  methods: {
    loadTeams() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.teams = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
  },

  watch: {
    '$route': 'loadTeams'
  }

}
</script>